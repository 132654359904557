import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

export function trackVideoMetadata({ category, type, action, video }) {
  pushToDataLayer({
    event: 'interaction',
    metadata: {
      interaction: {
        category,
        type,
        action,
      },
      video
    }
  })
}

export function trackVideoProgress({ title }) {
  pushToDataLayer({
    event: 'video_progress',
    metadata: {
      video: {
        title,
        progress: 25, // Deliberately always a quarter on OM request.
        type: 'video',
      }
    }
  })
}

export function getVideoMetaDataProps({ title, progress = undefined, duration }) {
  return {
    title,
    type: 'video',
    duration: Number(duration)?.toFixed(2),
    // progress: Number(progress)?.toFixed(2)
  }
}

export function useProgressTracker({ title }) {
  const trackedMarksRef = React.useRef(new Set())

  const VIDEO_PROGRESS_MARKS = [25, 50, 75, 100]

  return React.useCallback((played) => {
    const progress = Math.ceil(played * 100)

    VIDEO_PROGRESS_MARKS
      .filter(mark => progress >= mark && !trackedMarksRef.current.has(mark))
      .forEach(mark => {
        trackedMarksRef.current.add(mark)
        trackVideoProgress({ title })
      })
  }, [])
}
